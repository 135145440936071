import {FunctionComponent} from "react";
import styled from "styled-components";

import history_white from './history_white.svg';
import close from './close.svg';

const BetHistoryWrapper = styled.div<{ isOpen: boolean }>`
    position: absolute;
    right: 0;
    top: 45px;
    width: 550px;
    height: 440px;
  
    pointer-events: auto;
    transition: right 0.3s ease-in-out;
  
    ${props => !props.isOpen ? `
        right: -550px;    
    ` : ''}
`;
const BetHistoryHeader = styled.div`
    background-color: hsla(0,0%,42%,.85);
    font-size: 14px;
    font-weight: 400;
    height: 30px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: white;
    text-align: center;
    line-height: 26px;
  
    & > img {
      width: 20px;
      vertical-align: middle;
    }
    & > div {
      display: inline-block;
      line-height: 20px;
      vertical-align: middle;
      height: 20px;
    }
`;
const BetHistoryContent = styled.div`
    background-color: rgba(26, 26, 26, 0.8);
    height: 410px;
`;
const CloseButtonWrapper = styled.button`
    position: absolute;
    right: -3px;
    cursor: pointer;
    top: 4px;
    border: none;
    background: transparent;
  
    & > img {
        width: 20px;
    }
`;

export const BetHistory: FunctionComponent<{ isOpen: boolean; onClose?: () => unknown }> = (props) => {
    return (
        <BetHistoryWrapper isOpen={props.isOpen}>
            <BetHistoryHeader>
                <img src={history_white} /> <div>게임 기록</div>
                <CloseButtonWrapper onClick={() => props.onClose && props.onClose()}><img src={close} /></CloseButtonWrapper>
            </BetHistoryHeader>
            <BetHistoryContent>

            </BetHistoryContent>
        </BetHistoryWrapper>
    );
}