import {FunctionComponent, useState} from "react";
import styled from "styled-components";
import fscreen from 'fscreen';

import fullscreen from './fullscreen.svg';
import history from './history.svg';
import history_white from './history_white.svg';
import mute from './mute.svg';
import unmute from './unmute.svg';
import smallscreen from './smallscreen.svg';

const ButtonAreaWrapperDiv = styled.div`
    position: absolute;
    right: 5px;
    top: 5px;
    width: 500px;
    height: 34px;
    text-align: right;
`;
const Button = styled.button<{ active?: boolean }>`
    background-color: rgba(26, 26, 26, 0.6);
    border-radius: 50%;
    align-items: center;
    border: 0.0833rem solid rgba(255, 255, 255, 0.4);
    box-sizing: border-box;
    justify-content: center;
    text-align: center;
    pointer-events: auto;
    margin-left: 4px;
    position: relative;
    transition: background-color 0.5s ease 0s;
    width: 34px;
    height: 34px;
    cursor: pointer;
  
    ${props => props.active ? `
        background-color: hsla(0,0%,100%,.8);    
    ` : ''}
  
    & > img {
      width: 100%;
      height: 100%;
    }
`;

export const ButtonArea: FunctionComponent<{ isMute: boolean; onMute?: () => unknown; onHistoryOpen: () => unknown; isHistoryOpen: boolean }> = (props) => {
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

    fscreen.onfullscreenchange = (event) => {
        if (fscreen.fullscreenElement) {
            setIsFullscreen(true);
        } else {
            setIsFullscreen(false);
        }
    }
    const requestFullscreen = () => {
        if (isFullscreen) {
            fscreen.exitFullscreen();
            return;
        }
        fscreen.requestFullscreen(document.querySelector('.App')!);
    }

    return (
        <ButtonAreaWrapperDiv>
            <Button onClick={() => props.onMute && props.onMute()}><img src={props.isMute ? mute : unmute} /></Button>
            <Button active={props.isHistoryOpen} onClick={() => props.onHistoryOpen()}><img src={props.isHistoryOpen ? history : history_white} /></Button>
            <Button onClick={() => requestFullscreen()}>{isFullscreen ? <img src={smallscreen} /> : <img src={fullscreen} />}</Button>
        </ButtonAreaWrapperDiv>
    );
}