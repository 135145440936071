import {FunctionComponent, useEffect} from "react";
import styled from "styled-components";
import {BetChip} from "./BetChip";
import {BrowserView, MobileView} from "react-device-detect";

const BetWrapper = styled.div<{ disabled?: boolean }>`
    position: absolute;
    bottom: 90px;
    left: 50%;
    max-width: 900px;
    min-width: 640px;
    transform: translateX(-50%);
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  
    pointer-events: auto;
  
    transform-origin: bottom center;
    transition-duration: 300ms;
    ${props => props.disabled ? `
        bottom: 55px;
        transform: translateX(-50%) scale(0.9);
    ` : ''}
`;
const BetPanelElement = styled.div``;
const BetPanelHeader = styled.div`
    color: white;
    background: #56717c66;
    border-radius: 8px;
    font-weight: 400;
    height: 20px;
    margin-bottom: 0.25em;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
`;
const LineDiv = styled.div`
    height: 60px;
    display: flex;
    flex-direction: row;
    gap: 3px;
`;
const BetBackgroundDiv = styled.div<{ type: string, active?: boolean, disabled?: boolean }>`
    cursor: pointer;
    flex: 1;
    padding: 2px;
    height: 100%;
    border-radius: 15px;
  
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    ${props =>
          props.active
                  ? `background: linear-gradient(180deg,#ffebaa,#fbdb4f 97%); cursor: auto;`
                  : props.type === 'odd'
                      ? `background: linear-gradient(180deg,#20647b 6%,#6ca8bf 46%,#3d6685 99%);`
                      : props.type === 'even'
                              ? `background: linear-gradient(180deg,#a72f38 6%,#f66a6a 57%,#b15759 98%);`
                              : `background: linear-gradient(180deg,#9e8567 6%,#c6a27a 61%,#ae9b7c 99%);`}
  
    transition: opacity .5s ease-in;
    ${props => props.disabled ? `opacity: 0.4;` : ''}
    
    ${props => (!props.disabled && !props.active) ? `
        &:active > div {
          box-shadow: inset 0 0 0.75em 0 rgba(0,0,0,.5);
        }    
    ` : 'cursor: auto;'}
`;
const BetButtonDiv = styled.div<{type: string}>`
    height: 100%;
    border-radius: 13px;
    text-align: center;
  
    ${props => 
            props.type === 'odd'
                    ? `background: radial-gradient(94.05% 63.11% at 49.58% 0.38%,rgba(161,209,227,.85) 0%,transparent 100%),linear-gradient(180deg,#47b0d1,#2a4560);`
                    : props.type === 'even'
                            ? `background: radial-gradient(88.01% 67.65% at 49.58% 0.38%,hsla(0,87%,73%,.85) 0%,transparent 100%),linear-gradient(180deg,#e83535,#8b151f);`
                            : `background: radial-gradient(94.05% 63.11% at 49.58% 0.38%,rgba(255,247,232,.85) 0%,transparent 81.22%),linear-gradient(180deg,#f7d7a8,#71592f);`} 
`;
const BetItemNameDiv = styled.div`
    color: white;
    font-size: 20px;
    filter: drop-shadow(0 2px 4px rgba(0,0,0,.6));
    height: 100%;
    letter-spacing: -2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const BetItemOddsDiv = styled.div`
    margin-top: -18px;
    color: white;
    font-size: 13px;
    opacity: 0.7;
`;
const BetItemRangeDiv = styled.div`
    color: white;
    font-size: 10px;
    margin-top: -58px;
    white-space: nowrap;
    word-break: keep-all;
  
    & > div {
      display: inline-block;
      background: linear-gradient(0deg,hsla(31,37%,61%,.1),hsla(31,37%,61%,.1)),linear-gradient(180deg,rgba(77,77,77,.5),rgba(26,26,26,.5));
      border-radius: 10px;
      padding: 0 4px;
    }
`;

export const BetPanel: FunctionComponent<{ disabled: boolean, activeOdds: string[], disabledOdds: string[], betAmount: Record<string, number>, onBet?: (betItem: string) => unknown, }> = (props) => {
    return (
        <>
            <BrowserView>
                <BetWrapper disabled={props.disabled}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                        <BetPanelElement style={{ flex: 1.75 }}>
                            <BetPanelHeader>일반볼 홀짝</BetPanelHeader>
                            <LineDiv>
                                <BetBackgroundDiv id={"Bet_RegularOdd"} active={props.activeOdds.indexOf('RegularOdd') !== -1} disabled={props.disabledOdds.indexOf('RegularOdd') !== -1} type={"odd"}>
                                    <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOdd') === -1 && props.disabledOdds.indexOf('RegularOdd') === -1 && props.onBet && props.onBet('RegularOdd')}>
                                        <BetItemNameDiv><div>홀</div></BetItemNameDiv>
                                        <BetItemOddsDiv>1.97x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['RegularOdd']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_RegularEven"} active={props.activeOdds.indexOf('RegularEven') !== -1} disabled={props.disabledOdds.indexOf('RegularEven') !== -1} type={"even"}>
                                    <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEven') === -1 && props.disabledOdds.indexOf('RegularEven') === -1 && props.onBet && props.onBet('RegularEven')}>
                                        <BetItemNameDiv><div>짝</div></BetItemNameDiv>
                                        <BetItemOddsDiv>1.97x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['RegularEven']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                            </LineDiv>
                        </BetPanelElement>
                        <BetPanelElement style={{ flex: 1.75 }}>
                            <BetPanelHeader>파워볼 홀짝</BetPanelHeader>
                            <LineDiv>
                                <BetBackgroundDiv id={"Bet_PowerOdd"} active={props.activeOdds.indexOf('PowerOdd') !== -1} disabled={props.disabledOdds.indexOf('PowerOdd') !== -1} type={"odd"}>
                                    <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('PowerOdd') === -1 && props.disabledOdds.indexOf('PowerOdd') === -1 && props.onBet && props.onBet('PowerOdd')}>
                                        <BetItemNameDiv><div>P홀</div></BetItemNameDiv>
                                        <BetItemOddsDiv>1.97x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['PowerOdd']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_PowerEven"} active={props.activeOdds.indexOf('PowerEven') !== -1} disabled={props.disabledOdds.indexOf('PowerEven') !== -1} type={"even"}>
                                    <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('PowerEven') === -1 && props.disabledOdds.indexOf('PowerEven') === -1 && props.onBet && props.onBet('PowerEven')}>
                                        <BetItemNameDiv><div>P짝</div></BetItemNameDiv>
                                        <BetItemOddsDiv>1.97x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['PowerEven']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                            </LineDiv>
                        </BetPanelElement>
                        <BetPanelElement style={{ flex: 2.5 }}>
                            <BetPanelHeader>일반볼 대/중/소</BetPanelHeader>
                            <LineDiv>
                                <BetBackgroundDiv id={"Bet_RegularHigh"} active={props.activeOdds.indexOf('RegularHigh') !== -1} disabled={props.disabledOdds.indexOf('RegularHigh') !== -1} type={"size"}>
                                    <BetButtonDiv type={"size"} onClick={() => props.activeOdds.indexOf('RegularHigh') === -1 && props.disabledOdds.indexOf('RegularHigh') === -1 && props.onBet && props.onBet('RegularHigh')}>
                                        <BetItemNameDiv><div>대</div></BetItemNameDiv>
                                        <BetItemOddsDiv>2.5x</BetItemOddsDiv>
                                        <BetItemRangeDiv><div>81-130</div></BetItemRangeDiv>
                                        <BetChip amount={props.betAmount['RegularHigh']} withRange={true} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_RegularMedium"} active={props.activeOdds.indexOf('RegularMedium') !== -1} disabled={props.disabledOdds.indexOf('RegularMedium') !== -1} type={"size"}>
                                    <BetButtonDiv type={"size"} onClick={() => props.activeOdds.indexOf('RegularMedium') === -1 && props.disabledOdds.indexOf('RegularMedium') === -1 && props.onBet && props.onBet('RegularMedium')}>
                                        <BetItemNameDiv><div>중</div></BetItemNameDiv>
                                        <BetItemOddsDiv>2.4x</BetItemOddsDiv>
                                        <BetItemRangeDiv><div>65-80</div></BetItemRangeDiv>
                                        <BetChip amount={props.betAmount['RegularMedium']} withRange={true} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_RegularLow"} active={props.activeOdds.indexOf('RegularLow') !== -1} disabled={props.disabledOdds.indexOf('RegularLow') !== -1} type={"size"}>
                                    <BetButtonDiv type={"size"} onClick={() => props.activeOdds.indexOf('RegularLow') === -1 && props.disabledOdds.indexOf('RegularLow') === -1 && props.onBet && props.onBet('RegularLow')}>
                                        <BetItemNameDiv><div>소</div></BetItemNameDiv>
                                        <BetItemOddsDiv>2.5x</BetItemOddsDiv>
                                        <BetItemRangeDiv><div>15-64</div></BetItemRangeDiv>
                                        <BetChip amount={props.betAmount['RegularLow']} withRange={true} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                            </LineDiv>
                        </BetPanelElement>
                        <BetPanelElement style={{ flex: 4 }}>
                            <BetPanelHeader>일반볼 조합 (홀짝+언더오버)</BetPanelHeader>
                            <LineDiv>
                                <BetBackgroundDiv id={"Bet_RegularOddUnder"} type={"odd"} active={props.activeOdds.indexOf('RegularOddUnder') !== -1} disabled={props.disabledOdds.indexOf('RegularOddUnder') !== -1}>
                                    <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOddUnder') === -1 && props.disabledOdds.indexOf('RegularOddUnder') === -1 && props.onBet && props.onBet('RegularOddUnder')}>
                                        <BetItemNameDiv><div>홀언더</div></BetItemNameDiv>
                                        <BetItemOddsDiv>3.7x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['RegularOddUnder']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_RegularOddOver"} type={"odd"} active={props.activeOdds.indexOf('RegularOddOver') !== -1} disabled={props.disabledOdds.indexOf('RegularOddOver') !== -1}>
                                    <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOddOver') === -1 && props.disabledOdds.indexOf('RegularOddOver') === -1 && props.onBet && props.onBet('RegularOddOver')}>
                                        <BetItemNameDiv><div>홀오버</div></BetItemNameDiv>
                                        <BetItemOddsDiv>3.7x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['RegularOddOver']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_RegularEvenUnder"} type={"even"} active={props.activeOdds.indexOf('RegularEvenUnder') !== -1} disabled={props.disabledOdds.indexOf('RegularEvenUnder') !== -1}>
                                    <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEvenUnder') === -1 && props.disabledOdds.indexOf('RegularEvenUnder') === -1 && props.onBet && props.onBet('RegularEvenUnder')}>
                                        <BetItemNameDiv><div>짝언더</div></BetItemNameDiv>
                                        <BetItemOddsDiv>3.7x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['RegularEvenUnder']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_RegularEvenOver"} type={"even"} active={props.activeOdds.indexOf('RegularEvenOver') !== -1} disabled={props.disabledOdds.indexOf('RegularEvenOver') !== -1}>
                                    <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEvenOver') === -1 && props.disabledOdds.indexOf('RegularEvenOver') === -1 && props.onBet && props.onBet('RegularEvenOver')}>
                                        <BetItemNameDiv><div>짝오버</div></BetItemNameDiv>
                                        <BetItemOddsDiv>3.7x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['RegularEvenOver']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                            </LineDiv>
                        </BetPanelElement>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                        <BetPanelElement style={{ flex: 1.75 }}>
                            <BetPanelHeader>일반볼 언더오버</BetPanelHeader>
                            <LineDiv>
                                <BetBackgroundDiv id={"Bet_RegularUnder"} type={"odd"} active={props.activeOdds.indexOf('RegularUnder') !== -1} disabled={props.disabledOdds.indexOf('RegularUnder') !== -1}>
                                    <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularUnder') === -1 && props.disabledOdds.indexOf('RegularUnder') === -1 && props.onBet && props.onBet('RegularUnder')}>
                                        <BetItemNameDiv><div>언더</div></BetItemNameDiv>
                                        <BetItemOddsDiv>1.97x</BetItemOddsDiv>
                                        <BetItemRangeDiv><div>15-72</div></BetItemRangeDiv>
                                        <BetChip amount={props.betAmount['RegularUnder']} withRange={true} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_RegularOver"} type={"even"} active={props.activeOdds.indexOf('RegularOver') !== -1} disabled={props.disabledOdds.indexOf('RegularOver') !== -1}>
                                    <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularOver') === -1 && props.disabledOdds.indexOf('RegularOver') === -1 && props.onBet && props.onBet('RegularOver')}>
                                        <BetItemNameDiv><div>오버</div></BetItemNameDiv>
                                        <BetItemOddsDiv>1.97x</BetItemOddsDiv>
                                        <BetItemRangeDiv><div>73-130</div></BetItemRangeDiv>
                                        <BetChip amount={props.betAmount['RegularOver']} withRange={true} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                            </LineDiv>
                        </BetPanelElement>
                        <BetPanelElement style={{ flex: 1.75 }}>
                            <BetPanelHeader>파워볼 언더오버</BetPanelHeader>
                            <LineDiv>
                                <BetBackgroundDiv id={"Bet_PowerUnder"} type={"odd"} active={props.activeOdds.indexOf('PowerUnder') !== -1} disabled={props.disabledOdds.indexOf('PowerUnder') !== -1}>
                                    <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('PowerUnder') === -1 && props.disabledOdds.indexOf('PowerUnder') === -1 && props.onBet && props.onBet('PowerUnder')}>
                                        <BetItemNameDiv><div>P언더</div></BetItemNameDiv>
                                        <BetItemOddsDiv>1.97x</BetItemOddsDiv>
                                        <BetItemRangeDiv><div>0-4</div></BetItemRangeDiv>
                                        <BetChip amount={props.betAmount['PowerUnder']} withRange={true} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_PowerOver"} type={"even"} active={props.activeOdds.indexOf('PowerOver') !== -1} disabled={props.disabledOdds.indexOf('PowerOver') !== -1}>
                                    <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('PowerOver') === -1 && props.disabledOdds.indexOf('PowerOver') === -1 && props.onBet && props.onBet('PowerOver')}>
                                        <BetItemNameDiv><div>P오버</div></BetItemNameDiv>
                                        <BetItemOddsDiv>1.97x</BetItemOddsDiv>
                                        <BetItemRangeDiv><div>5-9</div></BetItemRangeDiv>
                                        <BetChip amount={props.betAmount['PowerOver']} withRange={true} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                            </LineDiv>
                        </BetPanelElement>
                        <BetPanelElement style={{ flex: 6.5 }}>
                            <BetPanelHeader>일반볼 조합 (홀짝+대중소)</BetPanelHeader>
                            <LineDiv>
                                <BetBackgroundDiv id={"Bet_RegularOddHigh"} type={"odd"} active={props.activeOdds.indexOf('RegularOddHigh') !== -1} disabled={props.disabledOdds.indexOf('RegularOddHigh') !== -1}>
                                    <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOddHigh') === -1 && props.disabledOdds.indexOf('RegularOddHigh') === -1 && props.onBet && props.onBet('RegularOddHigh')}>
                                        <BetItemNameDiv><div>홀대</div></BetItemNameDiv>
                                        <BetItemOddsDiv>4.4x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['RegularOddHigh']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_RegularOddMedium"} type={"odd"} active={props.activeOdds.indexOf('RegularOddMedium') !== -1} disabled={props.disabledOdds.indexOf('RegularOddMedium') !== -1}>
                                    <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOddMedium') === -1 && props.disabledOdds.indexOf('RegularOddMedium') === -1 && props.onBet && props.onBet('RegularOddMedium')}>
                                        <BetItemNameDiv><div>홀중</div></BetItemNameDiv>
                                        <BetItemOddsDiv>4.2x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['RegularOddMedium']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_RegularOddLow"} type={"odd"} active={props.activeOdds.indexOf('RegularOddLow') !== -1} disabled={props.disabledOdds.indexOf('RegularOddLow') !== -1}>
                                    <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOddLow') === -1 && props.disabledOdds.indexOf('RegularOddLow') === -1 && props.onBet && props.onBet('RegularOddLow')}>
                                        <BetItemNameDiv><div>홀소</div></BetItemNameDiv>
                                        <BetItemOddsDiv>4.4x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['RegularOddLow']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_RegularEvenHigh"} type={"even"} active={props.activeOdds.indexOf('RegularEvenHigh') !== -1} disabled={props.disabledOdds.indexOf('RegularEvenHigh') !== -1}>
                                    <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEvenHigh') === -1 && props.disabledOdds.indexOf('RegularEvenHigh') === -1 && props.onBet && props.onBet('RegularEvenHigh')}>
                                        <BetItemNameDiv><div>짝대</div></BetItemNameDiv>
                                        <BetItemOddsDiv>4.4x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['RegularEvenHigh']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_RegularEvenMedium"} type={"even"} active={props.activeOdds.indexOf('RegularEvenMedium') !== -1} disabled={props.disabledOdds.indexOf('RegularEvenMedium') !== -1}>
                                    <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEvenMedium') === -1 && props.disabledOdds.indexOf('RegularEvenMedium') === -1 && props.onBet && props.onBet('RegularEvenMedium')}>
                                        <BetItemNameDiv><div>짝중</div></BetItemNameDiv>
                                        <BetItemOddsDiv>4.2x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['RegularEvenMedium']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_RegularEvenLow"} type={"even"} active={props.activeOdds.indexOf('RegularEvenLow') !== -1} disabled={props.disabledOdds.indexOf('RegularEvenLow') !== -1}>
                                    <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEvenLow') === -1 && props.disabledOdds.indexOf('RegularEvenLow') === -1 && props.onBet && props.onBet('RegularEvenLow')}>
                                        <BetItemNameDiv><div>짝소</div></BetItemNameDiv>
                                        <BetItemOddsDiv>4.4x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['RegularEvenLow']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                            </LineDiv>
                        </BetPanelElement>
                    </div>
                </BetWrapper>
            </BrowserView>
            <MobileView>
                <BetWrapper disabled={props.disabled} style={{ width: 'calc(100% - 20px)', minWidth: 'calc(100% - 20px)', bottom: '130px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                        <BetPanelElement style={{ flex: 1.75 }}>
                            <BetPanelHeader>일반볼 홀짝</BetPanelHeader>
                            <LineDiv>
                                <BetBackgroundDiv id={"Bet_RegularOdd"} active={props.activeOdds.indexOf('RegularOdd') !== -1} disabled={props.disabledOdds.indexOf('RegularOdd') !== -1} type={"odd"} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOdd') === -1 && props.disabledOdds.indexOf('RegularOdd') === -1 && props.onBet && props.onBet('RegularOdd')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv style={{ fontSize: '17px' }}><div>홀</div></BetItemNameDiv>
                                        <BetItemOddsDiv>1.97x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['RegularOdd']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_RegularEven"} active={props.activeOdds.indexOf('RegularEven') !== -1} disabled={props.disabledOdds.indexOf('RegularEven') !== -1} type={"even"} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEven') === -1 && props.disabledOdds.indexOf('RegularEven') === -1 && props.onBet && props.onBet('RegularEven')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv style={{ fontSize: '17px' }}><div>짝</div></BetItemNameDiv>
                                        <BetItemOddsDiv>1.97x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['RegularEven']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                            </LineDiv>
                        </BetPanelElement>
                        <BetPanelElement style={{ flex: 1.75 }}>
                            <BetPanelHeader>파워볼 홀짝</BetPanelHeader>
                            <LineDiv>
                                <BetBackgroundDiv id={"Bet_PowerOdd"} active={props.activeOdds.indexOf('PowerOdd') !== -1} disabled={props.disabledOdds.indexOf('PowerOdd') !== -1} type={"odd"} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('PowerOdd') === -1 && props.disabledOdds.indexOf('PowerOdd') === -1 && props.onBet && props.onBet('PowerOdd')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv style={{ fontSize: '17px' }}><div>P홀</div></BetItemNameDiv>
                                        <BetItemOddsDiv>1.97x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['PowerOdd']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_PowerEven"} active={props.activeOdds.indexOf('PowerEven') !== -1} disabled={props.disabledOdds.indexOf('PowerEven') !== -1} type={"even"} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('PowerEven') === -1 && props.disabledOdds.indexOf('PowerEven') === -1 && props.onBet && props.onBet('PowerEven')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv style={{ fontSize: '17px' }}><div>P짝</div></BetItemNameDiv>
                                        <BetItemOddsDiv>1.97x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['PowerEven']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                            </LineDiv>
                        </BetPanelElement>
                        <BetPanelElement style={{ flex: 1.75 }}>
                            <BetPanelHeader>일반볼 언더오버</BetPanelHeader>
                            <LineDiv>
                                <BetBackgroundDiv id={"Bet_RegularUnder"} type={"odd"} active={props.activeOdds.indexOf('RegularUnder') !== -1} disabled={props.disabledOdds.indexOf('RegularUnder') !== -1} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularUnder') === -1 && props.disabledOdds.indexOf('RegularUnder') === -1 && props.onBet && props.onBet('RegularUnder')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv style={{ fontSize: '17px' }}><div>언더</div></BetItemNameDiv>
                                        <BetItemOddsDiv>1.97x</BetItemOddsDiv>
                                        <BetItemRangeDiv><div>15-72</div></BetItemRangeDiv>
                                        <BetChip amount={props.betAmount['RegularUnder']} withRange={true} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_RegularOver"} type={"even"} active={props.activeOdds.indexOf('RegularOver') !== -1} disabled={props.disabledOdds.indexOf('RegularOver') !== -1} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularOver') === -1 && props.disabledOdds.indexOf('RegularOver') === -1 && props.onBet && props.onBet('RegularOver')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv style={{ fontSize: '17px' }}><div>오버</div></BetItemNameDiv>
                                        <BetItemOddsDiv>1.97x</BetItemOddsDiv>
                                        <BetItemRangeDiv><div>73-130</div></BetItemRangeDiv>
                                        <BetChip amount={props.betAmount['RegularOver']} withRange={true} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                            </LineDiv>
                        </BetPanelElement>
                        <BetPanelElement style={{ flex: 1.75 }}>
                            <BetPanelHeader>파워볼 언더오버</BetPanelHeader>
                            <LineDiv>
                                <BetBackgroundDiv id={"Bet_PowerUnder"} type={"odd"} active={props.activeOdds.indexOf('PowerUnder') !== -1} disabled={props.disabledOdds.indexOf('PowerUnder') !== -1} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('PowerUnder') === -1 && props.disabledOdds.indexOf('PowerUnder') === -1 && props.onBet && props.onBet('PowerUnder')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv style={{ fontSize: '17px' }}><div>P언더</div></BetItemNameDiv>
                                        <BetItemOddsDiv>1.97x</BetItemOddsDiv>
                                        <BetItemRangeDiv><div>0-4</div></BetItemRangeDiv>
                                        <BetChip amount={props.betAmount['PowerUnder']} withRange={true} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_PowerOver"} type={"even"} active={props.activeOdds.indexOf('PowerOver') !== -1} disabled={props.disabledOdds.indexOf('PowerOver') !== -1} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('PowerOver') === -1 && props.disabledOdds.indexOf('PowerOver') === -1 && props.onBet && props.onBet('PowerOver')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv style={{ fontSize: '17px' }}><div>P오버</div></BetItemNameDiv>
                                        <BetItemOddsDiv>1.97x</BetItemOddsDiv>
                                        <BetItemRangeDiv><div>5-9</div></BetItemRangeDiv>
                                        <BetChip amount={props.betAmount['PowerOver']} withRange={true} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                            </LineDiv>
                        </BetPanelElement>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                        <BetPanelElement style={{ flex: 2.5 }}>
                            <BetPanelHeader>일반볼 대/중/소</BetPanelHeader>
                            <LineDiv>
                                <BetBackgroundDiv id={"Bet_RegularHigh"} active={props.activeOdds.indexOf('RegularHigh') !== -1} disabled={props.disabledOdds.indexOf('RegularHigh') !== -1} type={"size"} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"size"} onClick={() => props.activeOdds.indexOf('RegularHigh') === -1 && props.disabledOdds.indexOf('RegularHigh') === -1 && props.onBet && props.onBet('RegularHigh')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv style={{ fontSize: '17px' }}><div>대</div></BetItemNameDiv>
                                        <BetItemOddsDiv>2.5x</BetItemOddsDiv>
                                        <BetItemRangeDiv><div>81-130</div></BetItemRangeDiv>
                                        <BetChip amount={props.betAmount['RegularHigh']} withRange={true} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_RegularMedium"} active={props.activeOdds.indexOf('RegularMedium') !== -1} disabled={props.disabledOdds.indexOf('RegularMedium') !== -1} type={"size"} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"size"} onClick={() => props.activeOdds.indexOf('RegularMedium') === -1 && props.disabledOdds.indexOf('RegularMedium') === -1 && props.onBet && props.onBet('RegularMedium')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv style={{ fontSize: '17px' }}><div>중</div></BetItemNameDiv>
                                        <BetItemOddsDiv>2.4x</BetItemOddsDiv>
                                        <BetItemRangeDiv><div>65-80</div></BetItemRangeDiv>
                                        <BetChip amount={props.betAmount['RegularMedium']} withRange={true} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_RegularLow"} active={props.activeOdds.indexOf('RegularLow') !== -1} disabled={props.disabledOdds.indexOf('RegularLow') !== -1} type={"size"} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"size"} onClick={() => props.activeOdds.indexOf('RegularLow') === -1 && props.disabledOdds.indexOf('RegularLow') === -1 && props.onBet && props.onBet('RegularLow')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv style={{ fontSize: '17px' }}><div>소</div></BetItemNameDiv>
                                        <BetItemOddsDiv>2.5x</BetItemOddsDiv>
                                        <BetItemRangeDiv><div>15-64</div></BetItemRangeDiv>
                                        <BetChip amount={props.betAmount['RegularLow']} withRange={true} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                            </LineDiv>
                        </BetPanelElement>
                        <BetPanelElement style={{ flex: 4 }}>
                            <BetPanelHeader>일반볼 조합 (홀짝+언더오버)</BetPanelHeader>
                            <LineDiv>
                                <BetBackgroundDiv id={"Bet_RegularOddUnder"} type={"odd"} active={props.activeOdds.indexOf('RegularOddUnder') !== -1} disabled={props.disabledOdds.indexOf('RegularOddUnder') !== -1} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOddUnder') === -1 && props.disabledOdds.indexOf('RegularOddUnder') === -1 && props.onBet && props.onBet('RegularOddUnder')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv style={{ fontSize: '17px' }}><div>홀언더</div></BetItemNameDiv>
                                        <BetItemOddsDiv>3.7x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['RegularOddUnder']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_RegularOddOver"} type={"odd"} active={props.activeOdds.indexOf('RegularOddOver') !== -1} disabled={props.disabledOdds.indexOf('RegularOddOver') !== -1} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOddOver') === -1 && props.disabledOdds.indexOf('RegularOddOver') === -1 && props.onBet && props.onBet('RegularOddOver')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv style={{ fontSize: '17px' }}><div>홀오버</div></BetItemNameDiv>
                                        <BetItemOddsDiv>3.7x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['RegularOddOver']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_RegularEvenUnder"} type={"even"} active={props.activeOdds.indexOf('RegularEvenUnder') !== -1} disabled={props.disabledOdds.indexOf('RegularEvenUnder') !== -1} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEvenUnder') === -1 && props.disabledOdds.indexOf('RegularEvenUnder') === -1 && props.onBet && props.onBet('RegularEvenUnder')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv style={{ fontSize: '17px' }}><div>짝언더</div></BetItemNameDiv>
                                        <BetItemOddsDiv>3.7x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['RegularEvenUnder']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_RegularEvenOver"} type={"even"} active={props.activeOdds.indexOf('RegularEvenOver') !== -1} disabled={props.disabledOdds.indexOf('RegularEvenOver') !== -1} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEvenOver') === -1 && props.disabledOdds.indexOf('RegularEvenOver') === -1 && props.onBet && props.onBet('RegularEvenOver')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv style={{ fontSize: '17px' }}><div>짝오버</div></BetItemNameDiv>
                                        <BetItemOddsDiv>3.7x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['RegularEvenOver']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                            </LineDiv>
                        </BetPanelElement>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                        <BetPanelElement style={{ flex: 6.5 }}>
                            <BetPanelHeader>일반볼 조합 (홀짝+대중소)</BetPanelHeader>
                            <LineDiv>
                                <BetBackgroundDiv id={"Bet_RegularOddHigh"} type={"odd"} active={props.activeOdds.indexOf('RegularOddHigh') !== -1} disabled={props.disabledOdds.indexOf('RegularOddHigh') !== -1} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOddHigh') === -1 && props.disabledOdds.indexOf('RegularOddHigh') === -1 && props.onBet && props.onBet('RegularOddHigh')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv style={{ fontSize: '17px' }}><div>홀대</div></BetItemNameDiv>
                                        <BetItemOddsDiv>4.4x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['RegularOddHigh']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_RegularOddMedium"} type={"odd"} active={props.activeOdds.indexOf('RegularOddMedium') !== -1} disabled={props.disabledOdds.indexOf('RegularOddMedium') !== -1} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOddMedium') === -1 && props.disabledOdds.indexOf('RegularOddMedium') === -1 && props.onBet && props.onBet('RegularOddMedium')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv style={{ fontSize: '17px' }}><div>홀중</div></BetItemNameDiv>
                                        <BetItemOddsDiv>4.2x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['RegularOddMedium']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_RegularOddLow"} type={"odd"} active={props.activeOdds.indexOf('RegularOddLow') !== -1} disabled={props.disabledOdds.indexOf('RegularOddLow') !== -1} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOddLow') === -1 && props.disabledOdds.indexOf('RegularOddLow') === -1 && props.onBet && props.onBet('RegularOddLow')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv style={{ fontSize: '17px' }}><div>홀소</div></BetItemNameDiv>
                                        <BetItemOddsDiv>4.4x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['RegularOddLow']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_RegularEvenHigh"} type={"even"} active={props.activeOdds.indexOf('RegularEvenHigh') !== -1} disabled={props.disabledOdds.indexOf('RegularEvenHigh') !== -1} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEvenHigh') === -1 && props.disabledOdds.indexOf('RegularEvenHigh') === -1 && props.onBet && props.onBet('RegularEvenHigh')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv style={{ fontSize: '17px' }}><div>짝대</div></BetItemNameDiv>
                                        <BetItemOddsDiv>4.4x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['RegularEvenHigh']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_RegularEvenMedium"} type={"even"} active={props.activeOdds.indexOf('RegularEvenMedium') !== -1} disabled={props.disabledOdds.indexOf('RegularEvenMedium') !== -1} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEvenMedium') === -1 && props.disabledOdds.indexOf('RegularEvenMedium') === -1 && props.onBet && props.onBet('RegularEvenMedium')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv style={{ fontSize: '17px' }}><div>짝중</div></BetItemNameDiv>
                                        <BetItemOddsDiv>4.2x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['RegularEvenMedium']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_RegularEvenLow"} type={"even"} active={props.activeOdds.indexOf('RegularEvenLow') !== -1} disabled={props.disabledOdds.indexOf('RegularEvenLow') !== -1} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEvenLow') === -1 && props.disabledOdds.indexOf('RegularEvenLow') === -1 && props.onBet && props.onBet('RegularEvenLow')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv style={{ fontSize: '17px' }}><div>짝소</div></BetItemNameDiv>
                                        <BetItemOddsDiv>4.4x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['RegularEvenLow']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                            </LineDiv>
                        </BetPanelElement>
                    </div>
                </BetWrapper>
            </MobileView>
        </>
    );
}