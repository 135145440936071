import chip1000 from './1000.svg';
import chip5000 from './5000.svg';
import chip25000 from './25000.svg';
import chip100000 from './100000.svg';
import chip500000 from './500000.svg';
import chip1000000 from './1000000.svg';
import undo from './undo.svg';
import double from './double.svg';
import {FunctionComponent, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {BrowserView, MobileView} from "react-device-detect";

const ChipContainerDiv = styled.div<{ disabled?: boolean }>`
  position: absolute;
  text-align: center;
  pointer-events: auto;
  bottom: 2px;
  width: 100%;
  transition-duration: 300ms;

  ${props => props.disabled ? `
      bottom: -50px;
    ` : ''}
`;

const MobileChipContainerDiv = styled.div<{ disabled?: boolean }>`
    position: absolute;
    text-align: center;
    pointer-events: auto;
    bottom: 60px;
    width: 100%;
    transition-duration: 300ms;
    
    ${props => props.disabled ? `
      bottom: -50px;
    ` : ''}
`;

const ChipArea = styled.div`
  display: inline-block;
  width: 600px;
`;

const MobileChipArea = styled.div`
    display: inline-block;
    width: 100%;
`;

const ChipWrapper = styled.button<{ selected?: boolean }>`
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
    height: 50px;
    width: 50px;
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
    & > img {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      border: 2px solid ${props => props.selected ? 'white' : 'transparent'};
    }
`;
const MobileChipWrapper = styled.button<{ selected?: boolean }>`
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
    height: 45px;
    width: 45px;
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
    & > img {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      border: 2px solid ${props => props.selected ? 'white' : 'transparent'};
    }
`;
const MobileUndoButtonWrapperDiv = styled.div`
  width: 40px;
  display: inline-block;
  text-align: right;
  vertical-align: middle;
  padding-right: 5px;
  cursor: pointer;
`;
const UndoButtonWrapperDiv = styled.div`
    width: 100px;
    display: inline-block;
    text-align: right;
    vertical-align: middle;
    padding-right: 5px;
    cursor: pointer;
`;
const UndoButton = styled.button`
    cursor: pointer;
    width: 37px;
    height: 37px;
    vertical-align: middle;
    box-sizing: border-box;
    font-weight: 500;
    border-radius: 50%;
    background-color: rgba(26,26,26,.4);
    border-color: hsla(0,0%,100%,.2);
    color: hsla(0,0%,100%,.4);
    
    & > img {
        width: 100%;
        height: 100%;
    }
`;
const UndoLabel = styled.label`
    color: white;
    font-size: 12px;
    margin-right: 6px;
`;

const DoubleButtonWrapperDiv = styled.div`
    width: 100px;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    padding-left: 5px;
    cursor: pointer;
`;
const MobileDoubleButtonWrapperDiv = styled.div`
    width: 40px;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    padding-left: 5px;
    cursor: pointer;
`;
const DoubleButton = styled.button`
    cursor: pointer;
    width: 37px;
    height: 37px;
    vertical-align: middle;
    box-sizing: border-box;
    font-weight: 500;
    border-radius: 50%;
    background-color: rgba(26,26,26,.4);
    border-color: hsla(0,0%,100%,.2);
    color: hsla(0,0%,100%,.4);

    & > img {
        width: 100%;
        height: 100%;
    }
`;
const DoubleLabel = styled.label`
    color: white;
    font-size: 12px;
    margin-left: 6px;
`;

const FlyingChipContainerDiv = styled.div`
    
`;
const FlyingChipDiv = styled.div<{ left: number, top: number, done: boolean }>`
    position: absolute;
    left: ${props => props.left}px;
    top: ${props => props.top}px;
    transition-property: left, top, opacity;
    transition-duration: 500ms, 500ms, 100ms;
    transition-delay: 0ms, 0ms, 500ms;
  
    ${props => props.done ? `
        & > button > img {
            width: 32px !important;
        }
        margin-left: 5px;
        margin-top: 36px;
        opacity: 0;
    ` : ''}
`;

interface IFlyingChip {
    status: 'CREATED' | 'FLYING' | 'ENDED';
    amount: number;
    startTop: number;
    startLeft: number;
    endTop: number;
    endLeft: number;
}

export const Chips: FunctionComponent<{ disabled: boolean, onChange?: (chipAmount: number) => unknown, onUndo?: () => unknown, onDouble?: () => unknown, onLoad?: (func: (chipAmount: number, endTop: number, endLeft: number) => unknown) => unknown }> = (props) => {
    const [selectedChip, setSelectedChip] = useState<number>(0);
    const [flyingChips, setFlyingChips] = useState<IFlyingChip[]>([]);

    const chipIndex: Record<number, number> = {
        1000: 0,
        5000: 1,
        25000: 2,
        100000: 3,
        500000: 4,
        1000000: 5,
    };
    const chipRefs = [useRef<HTMLButtonElement>(null), useRef<HTMLButtonElement>(null), useRef<HTMLButtonElement>(null), useRef<HTMLButtonElement>(null), useRef<HTMLButtonElement>(null), useRef<HTMLButtonElement>(null)];

    const addFlyingChip = (chipAmount: number, endTop: number, endLeft: number) => {
        const chipElement = chipRefs[chipIndex[chipAmount]]?.current;
        if (!chipElement) return;

        let top = 0, left = 0, currentElement: HTMLElement = chipElement as HTMLElement;
        while (currentElement.id !== 'ChipWrapper') {
            top += currentElement!.offsetTop;
            left += currentElement!.offsetLeft;
            currentElement = currentElement!.offsetParent as HTMLElement;
        }

        setFlyingChips([...flyingChips, {
            status: 'CREATED',
            amount: chipAmount,
            startTop: top,
            startLeft: left,
            endTop,
            endLeft,
        }]);

        if (props.onLoad) {
            props.onLoad(addFlyingChip);
        }
    }

    useEffect(() => {
        if (props.onLoad) {
            props.onLoad(addFlyingChip);
        }
    }, []);

    useEffect(() => {
        const flyingChip = flyingChips.find((flyingChip) => flyingChip.status === 'CREATED');
        if (flyingChip) {
            flyingChip.status = 'FLYING';
            setFlyingChips([...flyingChips]);
        }

        if (props.onLoad) {
            props.onLoad(addFlyingChip);
        }
    }, [flyingChips]);

    useEffect(() => {
        if (props.onChange) {
            props.onChange(selectedChip);
        }
    }, [selectedChip]);

    return (
        <>
            <BrowserView>
                <ChipContainerDiv disabled={props.disabled}>
                    <ChipArea>
                        <UndoButtonWrapperDiv onClick={() => props.onUndo && props.onUndo()}>
                            <UndoLabel htmlFor={"btnUndo"}>실행 취소</UndoLabel>
                            <UndoButton id={"btnUndo"}><img src={undo} /></UndoButton>
                        </UndoButtonWrapperDiv>
                        <ChipWrapper selected={selectedChip === 1000} onClick={() => setSelectedChip(1000)} ref={chipRefs[0]}><img src={chip1000} alt="1000" /></ChipWrapper>
                        <ChipWrapper selected={selectedChip === 5000} onClick={() => setSelectedChip(5000)} ref={chipRefs[1]}><img src={chip5000} alt="5000" /></ChipWrapper>
                        <ChipWrapper selected={selectedChip === 25000} onClick={() => setSelectedChip(25000)} ref={chipRefs[2]}><img src={chip25000} alt="25000" /></ChipWrapper>
                        <ChipWrapper selected={selectedChip === 100000} onClick={() => setSelectedChip(100000)} ref={chipRefs[3]}><img src={chip100000} alt="100000" /></ChipWrapper>
                        <ChipWrapper selected={selectedChip === 500000} onClick={() => setSelectedChip(500000)} ref={chipRefs[4]}><img src={chip500000} alt="500000" /></ChipWrapper>
                        <ChipWrapper selected={selectedChip === 1000000} onClick={() => setSelectedChip(1000000)} ref={chipRefs[5]}><img src={chip1000000} alt="1000000" /></ChipWrapper>
                        <DoubleButtonWrapperDiv onClick={() => props.onDouble && props.onDouble()}>
                            <DoubleButton id={"btnDouble"}><img src={double} /></DoubleButton>
                            <DoubleLabel htmlFor={"btnDouble"}>더블</DoubleLabel>
                        </DoubleButtonWrapperDiv>
                    </ChipArea>
                </ChipContainerDiv>
                <FlyingChipContainerDiv>
                    {flyingChips.filter((flyingChip) => flyingChip.status !== 'ENDED').map((flyingChip) =>
                        <FlyingChipDiv done={flyingChip.status === 'FLYING'} left={flyingChip.status === 'FLYING' ? flyingChip.endLeft : flyingChip.startLeft} top={flyingChip.status === 'FLYING' ? flyingChip.endTop : flyingChip.startTop}>
                            <ChipWrapper selected={false}>
                                <img src={flyingChip.amount === 1000
                                            ? chip1000
                                            : flyingChip.amount === 5000
                                            ? chip5000
                                            : flyingChip.amount === 25000
                                            ? chip25000
                                            : flyingChip.amount === 100000
                                            ? chip100000
                                            : flyingChip.amount === 500000
                                            ? chip500000
                                            : chip1000000}
                                />
                            </ChipWrapper>
                        </FlyingChipDiv>)}
                </FlyingChipContainerDiv>
            </BrowserView>
            <MobileView>
                <MobileChipContainerDiv disabled={props.disabled}>
                    <MobileChipArea>
                        <MobileUndoButtonWrapperDiv onClick={() => props.onUndo && props.onUndo()}>
                            <UndoButton id={"btnUndo"}><img src={undo} /></UndoButton>
                        </MobileUndoButtonWrapperDiv>
                        <MobileChipWrapper selected={selectedChip === 1000} onClick={() => setSelectedChip(1000)} ref={chipRefs[0]}><img src={chip1000} alt="1000" /></MobileChipWrapper>
                        <MobileChipWrapper selected={selectedChip === 5000} onClick={() => setSelectedChip(5000)} ref={chipRefs[1]}><img src={chip5000} alt="5000" /></MobileChipWrapper>
                        <MobileChipWrapper selected={selectedChip === 25000} onClick={() => setSelectedChip(25000)} ref={chipRefs[2]}><img src={chip25000} alt="25000" /></MobileChipWrapper>
                        <MobileChipWrapper selected={selectedChip === 100000} onClick={() => setSelectedChip(100000)} ref={chipRefs[3]}><img src={chip100000} alt="100000" /></MobileChipWrapper>
                        <MobileChipWrapper selected={selectedChip === 500000} onClick={() => setSelectedChip(500000)} ref={chipRefs[4]}><img src={chip500000} alt="500000" /></MobileChipWrapper>
                        <MobileChipWrapper selected={selectedChip === 1000000} onClick={() => setSelectedChip(1000000)} ref={chipRefs[5]}><img src={chip1000000} alt="1000000" /></MobileChipWrapper>
                        <MobileDoubleButtonWrapperDiv onClick={() => props.onDouble && props.onDouble()}>
                            <DoubleButton id={"btnDouble"}><img src={double} /></DoubleButton>
                        </MobileDoubleButtonWrapperDiv>
                    </MobileChipArea>
                </MobileChipContainerDiv>
                <FlyingChipContainerDiv>
                    {flyingChips.filter((flyingChip) => flyingChip.status !== 'ENDED').map((flyingChip) =>
                        <FlyingChipDiv done={flyingChip.status === 'FLYING'} left={flyingChip.status === 'FLYING' ? flyingChip.endLeft : flyingChip.startLeft} top={flyingChip.status === 'FLYING' ? flyingChip.endTop : flyingChip.startTop}>
                            <MobileChipWrapper selected={false}>
                                <img src={flyingChip.amount === 1000
                                    ? chip1000
                                    : flyingChip.amount === 5000
                                        ? chip5000
                                        : flyingChip.amount === 25000
                                            ? chip25000
                                            : flyingChip.amount === 100000
                                                ? chip100000
                                                : flyingChip.amount === 500000
                                                    ? chip500000
                                                    : chip1000000}
                                />
                            </MobileChipWrapper>
                        </FlyingChipDiv>)}
                </FlyingChipContainerDiv>
            </MobileView>
        </>
    )
}