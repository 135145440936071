import React, {useEffect, useRef, useState} from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import {OvenPlayerWrapper} from "./components/OvenPlayerWrapper";
import styled from "styled-components";
import {Header} from "./components/Header";
import {BetPanel} from "./components/BetPanel";
import {Balance} from "./components/Balance";
import {BetAmount} from "./components/BetAmount";
import {Chips} from "./components/Chips";
import {CurrentBalls} from "./components/CurrentBalls";
import {Road} from "./components/Road";
import {ButtonArea} from "./components/ButtonArea";
import {Logo} from "./components/Logo";
import {Timer} from "./components/Timer";
import {BetHistory} from "./components/BetHistory";
import {Tooltip} from "./components/Tooltip";
import swal from "sweetalert2";

const LayerDiv = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
`;

const CenteredDiv = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-height: 100%;
    transform: translate(-50%, -50%);
`;

const MobileVideoWrapper = styled.div<{ width: number }>`
    min-width: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
    transition-duration: 300ms;
    width: ${(props) => props.width}px;
`;
const MobileVideoDimmer = styled.div`
    background: linear-gradient(180deg,transparent,rgba(2,33,58,.8) 46.04%,#02213a 50.89%,rgba(2,33,58,.8) 56.21%,transparent);
    position: absolute;
    height: 190px;
    width: 100%;
    margin-top: -95px;
`;

const _BET_ITEMS = [
    'RegularOdd', 'RegularEven',
    'PowerOdd', 'PowerEven',
    'RegularHigh', 'RegularMedium', 'RegularLow',
    'RegularOddUnder', 'RegularOddOver', 'RegularEvenUnder', 'RegularEvenOver',
    'RegularUnder', 'RegularOver',
    'PowerUnder', 'PowerOver',
    'RegularOddHigh', 'RegularOddMedium', 'RegularOddLow', 'RegularEvenHigh', 'RegularEvenMedium', 'RegularEvenLow',
];

function useInterval(callback: Function, delay: number) {
  const savedCallback = useRef<Function>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function App() {
  const [gameStage, setGameStage] = useState<'Betting' | 'Drawing'>('Betting');
  const [currentBalls, setCurrentBalls] = useState<number[]>([]);
  const [winBets, setWinBets] = useState<string[]>([]);
  const [nextGameDate, setNextGameDate] = useState<Date>(new Date());
  const [nextGameTime, setNextGameTime] = useState<number>(-1);
  const [selectedChip, setSelectedChip] = useState<number | undefined>();
  const [betHistory, setBetHistory] = useState<{ betItem: string, amount: number }[]>([]);
  const [betAmount, setBetAmount] = useState<Record<string, number>>({});
  const [isBetHistoryOpen, setIsBetHistoryOpen] = useState<boolean>(false);
  const [isMute, setIsMute] = useState<boolean>(true);

  useEffect(() => {
    const ws = new WebSocket('wss://api.evostreams.net');
    ws.onmessage = (event) => {
      const rawData = event.data;
      const data = JSON.parse(rawData);

      if (data.type === 'ballState') {
        setCurrentBalls(data.value.map((ball: { status: string, id: number, value?: number }) => ball.value !== undefined ? ball.value : ball.status === 'Shuffling' ? -1 : undefined).filter((ball: number | undefined) => ball !== undefined));
      } else if (data.type === 'gameState' || data.type === 'gameStateRaw') {
        if (data.value.stage === 'Betting') {
          resetBetAmount();
          setBetHistory([]);
          setGameStage('Betting');
          setWinBets([]);
        } else {
          setGameStage('Drawing');
        }

        if (data.value.winBetSpots) {
          setWinBets([...data.value.winBetSpots]);
        }

        if (data.value.nextStageIn !== undefined) {
          const emitDate = new Date(data.time);
          emitDate.setMilliseconds(emitDate.getMilliseconds() + data.value.nextStageIn);
          setNextGameDate(emitDate);
        }
      }
    }
    resetBetAmount();
  }, []);

  useInterval(() => {
    const now = new Date();
    const leftSeconds = Math.floor((nextGameDate.getTime() - now.getTime()) / 1000);

    setNextGameTime(leftSeconds);
  }, 100);

  const resetBetAmount = () => {
    const newBetAmount: Record<string, number> = {};
    for (const betItem of _BET_ITEMS) {
        newBetAmount[betItem] = 0;
    }
    setBetAmount(newBetAmount);
  }

  const [addFlyingChip, setAddFlyingChip] = useState<(chipAmount: number, endTop: number, endLeft: number) => unknown>(() => () => {});
  const flyChip = (chipAmount: number, endBet: string) => {
    const endElement = document.getElementById(`Bet_${endBet}`);

    const endTop = endElement!.getBoundingClientRect().top;
    const endLeft = endElement!.getBoundingClientRect().left;

    const parent = document.getElementById('BetWrapper');
    const parentTop = parent!.getBoundingClientRect().top;
    const parentLeft = parent!.getBoundingClientRect().left;

    addFlyingChip(chipAmount, endTop - parentTop, endLeft - parentLeft);
  }

  const Bet = (betItem: string) => {
    swal.fire({
        title: '로그인 후 이용하세요',
        icon: 'error',
        confirmButtonText: '확인',
    });
    // if (selectedChip === undefined) {
    //   return;
    // }
    //
    // flyChip(selectedChip, betItem);
    //
    // setTimeout(() => {
    //   const newBetHistory = [...betHistory];
    //   newBetHistory.push({ betItem, amount: selectedChip });
    //   setBetHistory(newBetHistory);
    //
    //   const newBetAmount = { ...betAmount };
    //   newBetAmount[betItem] += selectedChip;
    //   setBetAmount(newBetAmount);
    // }, 500);
  }

  const UndoBet = () => {
    if (betHistory.length === 0) {
      return;
    }

    const lastBet = betHistory[betHistory.length - 1];
    const newBetHistory = [...betHistory];
    newBetHistory.splice(betHistory.length - 1, 1);
    setBetHistory(newBetHistory);

    const newBetAmount = { ...betAmount };
    newBetAmount[lastBet.betItem] -= lastBet.amount;
    setBetAmount(newBetAmount);
  }

  const DoubleBet = () => {
    const newBetHistory = [...betHistory];
    for (const bet of newBetHistory) {
      bet.amount *= 2;
    }
    setBetHistory(newBetHistory);

    const newBetAmount = { ...betAmount };
    for (const betItem of _BET_ITEMS) {
      newBetAmount[betItem] *= 2;
    }
    setBetAmount(newBetAmount);
  }

  return (
    <div className="App">
      <BrowserView>
        <CenteredDiv>
          <OvenPlayerWrapper autoStart={true} autoFallback={true} mute={isMute} controls={false} sources={[{
            "type": "webrtc",
            "file": "wss://bamboo.evostreams.net:3334/evostreams/stream"
          }, {
            "type": "llhls",
            "file": "https://bamboo.evostreams.net:3334/evostreams/stream/llhls.m3u8"
          }]} />
          <LayerDiv style={{ pointerEvents: 'auto' }} />
          <LayerDiv><Header minBet={1000} maxBet={20000000} /></LayerDiv>
          <LayerDiv><Logo /></LayerDiv>
          <LayerDiv><Balance balance={0} /></LayerDiv>
          <LayerDiv><BetAmount betAmount={Object.values(betAmount).reduce((curr, acc) => acc + curr, 0)} /></LayerDiv>
          {gameStage === 'Drawing' ? <LayerDiv><CurrentBalls balls={currentBalls} /></LayerDiv> : null}
          <LayerDiv id={"BetWrapper"}><BetPanel
                        activeOdds={[...winBets]}
                        disabledOdds={gameStage === 'Drawing' ? [..._BET_ITEMS.filter((item) => winBets.indexOf(item) === -1)] : []}
                        disabled={gameStage === 'Drawing'}
                        betAmount={betAmount}
                        onBet={(betItem) => Bet(betItem)}
                      /></LayerDiv>
          <LayerDiv id={"ChipWrapper"}><Chips disabled={gameStage === 'Drawing'} onLoad={(addFlyingChipFunc) => setAddFlyingChip(() => addFlyingChipFunc)} onChange={(chipAmount) => setSelectedChip(chipAmount)} onUndo={() => UndoBet()} onDouble={() => DoubleBet()} /></LayerDiv>
          <LayerDiv><Timer leftTime={gameStage === 'Betting' ? nextGameTime : -1} /></LayerDiv>
          {/*<LayerDiv><Road /></LayerDiv>*/}
          <LayerDiv><ButtonArea isMute={isMute} onMute={() => setIsMute(!isMute)} isHistoryOpen={isBetHistoryOpen} onHistoryOpen={() => setIsBetHistoryOpen(!isBetHistoryOpen)} /></LayerDiv>
          <LayerDiv><BetHistory isOpen={isBetHistoryOpen} onClose={() => setIsBetHistoryOpen(false)} /></LayerDiv>
          {/*<LayerDiv><Tooltip message={'로그인 후 이용하세요'} left={300} top={300} /></LayerDiv>*/}
        </CenteredDiv>
      </BrowserView>
      <MobileView>
        <div style={{ width: '100%', overflow: 'hidden' }}>
          <LayerDiv style={{ background: 'linear-gradient(45deg, rgba(30, 65, 87, 0) 0%, rgba(30, 65, 87, 0.8) 50%, rgba(30, 65, 87, 0) 100%), linear-gradient(45deg, rgb(19, 18, 35) -5%, rgb(30, 65, 87) 50%, rgb(19, 18, 35) 105%)' }} />
          <MobileVideoWrapper width={gameStage === 'Betting' ? 400 : 650}>
            <OvenPlayerWrapper autoStart={true} autoFallback={true} mute={isMute} controls={false} sources={[{
              "type": "webrtc",
              "file": "wss://bamboo.evostreams.net:3334/evostreams/stream"
            }, {
              "type": "llhls",
              "file": "https://bamboo.evostreams.net:3334/evostreams/stream/llhls.m3u8"
            }]} />
          </MobileVideoWrapper>
          <MobileVideoDimmer />
          {gameStage === 'Drawing' ? <CurrentBalls balls={currentBalls} /> : null}
          <div id={"BetWrapper"}><BetPanel
              activeOdds={[...winBets]}
              disabledOdds={gameStage === 'Drawing' ? [..._BET_ITEMS.filter((item) => winBets.indexOf(item) === -1)] : []}
              disabled={gameStage === 'Drawing'}
              betAmount={betAmount}
              onBet={(betItem) => Bet(betItem)}
          /></div>
          <LayerDiv id={"ChipWrapper"}><Chips disabled={gameStage === 'Drawing'} onLoad={(addFlyingChipFunc) => setAddFlyingChip(() => addFlyingChipFunc)} onChange={(chipAmount) => setSelectedChip(chipAmount)} onUndo={() => UndoBet()} onDouble={() => DoubleBet()} /></LayerDiv>
        </div>
        {/*<LayerDiv style={{ pointerEvents: 'auto' }} />*/}
        <LayerDiv><Balance balance={0} /></LayerDiv>
        <LayerDiv><BetAmount betAmount={Object.values(betAmount).reduce((curr, acc) => acc + curr, 0)} /></LayerDiv>
        <LayerDiv><Header minBet={1000} maxBet={20000000} /></LayerDiv>
        <LayerDiv><Timer leftTime={gameStage === 'Betting' ? nextGameTime : -1} /></LayerDiv>
      </MobileView>
    </div>
  );
}

export default App;
